<template>
  <div class="divs">
    <ToggleSetting :listdata="listdata"></ToggleSetting>
  </div>
</template>

<script>
// let id = 1000
import ToggleSetting from '@c/ToggleSetting'
export default {
  components: {
    ToggleSetting
  },
  data() {
    return {
      listdata: [
        { name: '门店信息', link: 'InfoMsettings' },
        // { name: '人员设置', link: 'PeopleManage' },
        // { name: '角色设置', link: 'CharacterSet' },
        { name: '节点管理', link: 'NodeManagement' },
        // { name: '营销活动', link: 'MarketingCampaign' },
        // { name: '核算科目', link: 'BusinessAccounting' },
        // { name: '分析数据设置', link: 'TheDataSet' },
        // { name: '工作日设置', link: 'WeekdaySetting' }
        { name: '云盘设置', link: 'cloudStorage' },
         { name: '导入查询', link: 'Inquire' },
        { name: '操作日志', link: 'journal' }
      ]
    }
  },
  methods: {}
}
</script>
<style lang="scss">
.divs {
  font-size: 20px;
  color: red;
}
</style>
